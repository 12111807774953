/* --------------------------
    Sequence Highlighting
 ----------------------------*/
.highlight-row {
	font-family: monospace;
}

.highlight-line-number {
	white-space: pre;
	text-align: right
}

.highlight-section {
	position: relative;
}

.highlight-highlight {
	top: 0;
	left: 0;
	position: absolute;
	padding-left: 0.25em;
}

.highlight-highlight[data-type="mutation"] .highlight-highlight-area {
	/* background: rgb(131, 235, 131); */
	background: #02E402;
}

.highlight-highlight[data-type="o_link_glycosylation"]
	.highlight-highlight-area {
	border-bottom: 3px solid blue;
}

.highlight-highlight[data-type="n_link_glycosylation"]
	.highlight-highlight-area {
	border-bottom: 3px solid red;
}

.highlight-highlight[data-type="site_annotation"] .highlight-highlight-area {
	/* background-image: linear-gradient(transparent, rgb(211, 113, 211)); */
	background: 3px orange;
	padding-top: 1px;
}

.highlight-highlight[data-type="phosphorylation"]
	.highlight-highlight-area {
	background: #FFA9A9;
}

.highlight-highlight[data-type="glycation"]
	.highlight-highlight-area {
	background: rgb(214, 166, 255);
}

.highlight-highlight[data-type="text_search"]
	.highlight-highlight-area {
	background: #33FFE0;
}

.sequnce_highlight {
	display: block;
	display: inline-table;
	padding: 9px;
	margin: 0;
	font-size: 14px;
	line-height: 1.42857143;
	color: rgba(0, 0, 0);
	/* background-color: #e6e6e6; */
	background-color: rgba(0, 0, 0, 0.05);
	border: 1px solid #ddd;
	border-radius: 4px;
	overflow-y: scroll;
}

/*n_link_glycosylation"*/
.sequnce1 {
	border-bottom: 3px solid red;
	/* font-family: monospace; */
	font-size: 16px;
	font-weight: 500;
	letter-spacing: 1px;
	margin: 0 5px;
}

/*o_link_glycosylation"*/
.sequnce2 {
	border-bottom: 3px solid blue;
	/* font-family: monospace; */
	/* font-size: 14px; */
	font-size: 16px;
	font-weight: 500;
	letter-spacing: 1px;
	margin: 0 5px;
}
/*mutation*/
.sequnce3 {
	background: rgb(131, 235, 131);
	/* font-family: monospace; */
	/* font-size: 14px; */
	font-size: 16px;
	font-weight: 500;
	letter-spacing: 1px;
	margin: 0 5px;
}
/*Sequon*/
.sequnce4 {
	background: rgb(244, 151, 37);
	background: #F5B464;
	/* font-family: monospace; */
	/* font-size: 14px; */
	font-size: 16px;
	font-weight: 500;
	letter-spacing: 1px;
	margin: 0 5px;
}
.sequnce5 {
	/* background: #FFA9A9; */
	background: #FEB9B9;
	/* background: rgb(255, 135, 135); */
	font-size: 16px;
	font-weight: 500;
	letter-spacing: 1px;
	margin: 0 5px;
}
.sequnce6 {
	background: rgb(214, 166, 255);
	font-size: 16px;
	font-weight: 500;
	letter-spacing: 1px;
	margin: 0 5px;
}
.sequnce7 {
	font-size: 16px !important;
	font-weight: 500 !important;
	letter-spacing: 1px;
	margin: 0 5px;
	padding-left: 0px;
	border-radius: 0px !important;
	padding-top: 1.5px !important;
	padding-bottom: 1.5px !important;
	background-color: white !important;
}
.sequnce8 {
	font-size: 16px !important;
	font-weight: 500 !important;
	letter-spacing: 1px;
	margin: 0 5px;
	border-radius: 0px !important;
	padding-top: 1.5px !important;
	padding-bottom: 1.5px !important;
	background-color: white !important;
	width: 215px;
	/* z-index: 100; */
	/* position: absolute; */
	/* overflow: visible; */
	/* display: block; */
	/* transform: translateY(-50%); */
}

.pop-up {
	/* z-index: 100;
	width: fit-content;
	z-index: 500 !important; */
	/* position: absolute; */
	/* overflow: visible; */
	/* display: block; */
	/* transform: translateY(-500%); */
}

.highlight-panel-categories {
	margin: 0 0 0 30px;
	padding: 5px;
	width: 100%;
	overflow: auto;
}

.sequence-panel {
	margin-left: 5%;
}

.content-box {
	display: flex;
	flex-wrap: wrap;
	flex-direction: row;
	overflow: auto;
}

@media (max-width: 1345px) {
	/* @media (max-width: 757px) { */
	.content-box {
		display: flex;
		flex-wrap: wrap;
		flex-direction: row;
		overflow: auto;
	}
	.content-active {
		order: -1;
		flex: 1 0 100%;
	}
}

.sequencePreClass {
	border: 0px;
	padding: 0px;
	margin-bottom: 0px;
	font-family: monospace;
	font-size: 14px;
}

.highlight-text {
	font-family: monospace !important;
	font-size: 14px;
	position: relative;
	z-index: 10;
}
