/* Mastodon embed feed timeline v3.9.1 */
/* More info at: */
/* https://gitlab.com/idotj/mastodon-embed-feed-timeline */

/* Variables */
:root {
  --text-max-lines: none;
}

/* Theme colors */
:root,
html[data-theme="light"] {
  --bg-color: #fff;
  --bg-hover-color: #d9e1e8;
  --line-gray-color: #c0cdd9;
  --content-text: #000;
  --link-color: #3a3bff;
  --error-text-color: #8b0000;
}
html[data-theme="dark"] {
  --bg-color: #282c37;
  --bg-hover-color: #313543;
  --line-gray-color: #393f4f;
  --content-text: #fff;
  --link-color: #8c8dff;
  --error-text-color: #fe6c6c;
}

/* Main container */
.mt-timeline {
  /* height: 100%; */
  height: 500px;;
  overflow-y: auto;
  position: relative;
  background: var(--bg-color);
  /* scrollbar-color: var(--bg-hover-color) rgba(0, 0, 0, 0.1); */
}
.mt-timeline a:link,
.mt-timeline a:active,
.mt-timeline a {
  text-decoration: none;
  color: var(--link-color);
}
.mt-timeline a:not(.toot-preview-link):hover {
  text-decoration: underline;
}
.mt-timeline::-webkit-scrollbar {
  width: 0.75rem;
  height: 0.75rem;
}
.mt-timeline::-webkit-scrollbar-corner {
  background: transparent;
}
.mt-timeline::-webkit-scrollbar-thumb {
  border: 0 var(--content-text);
  border-radius: 2rem;
  /* background: var(--bg-hover-color); */
  background: #757373;
}
.mt-timeline::-webkit-scrollbar-track {
  border: 0 var(--content-text);
  border-radius: 0;
  background: rgba(0, 0, 0, 0.1);
}

.mt-body {
  padding: 0rem 0.25rem 0rem 0.25rem; /* 1rem 1.5rem; */
  white-space: pre-wrap;
  word-wrap: break-word;
}
.mt-body .invisible {
  font-size: 0;
  line-height: 0;
  display: inline-block;
  width: 0;
  height: 0;
  position: absolute;
}

/* Toot container */
.mt-toot {
  margin: 0.25rem 0.025rem 0.25rem 0rem; /* 0.25rem; */
  padding: 1rem 0.5rem 1.5rem 0.5rem; /* 1rem 0.5rem 1.5rem 4rem; */
  position: relative;
  min-height: 3.75rem;
  background-color: transparent;
  border-bottom: 1px solid var(--line-gray-color);
}
.mt-toot:hover,
.mt-toot:focus {
  cursor: pointer;
  background-color: var(--bg-hover-color);
}
.mt-toot p:last-child {
  margin-bottom: 0;
}

/* User avatar and name */
.mt-avatar {
  /* width: 3rem;
  height: 3rem; */
  /* position: absolute; */
  /* top: 1rem; */
  /* left: 0.25rem; */
  border-radius: 0.25rem;
  overflow: hidden;
}
.mt-avatar-image img {
  /* width: 100%; */
  height: 32px;
}
.mt-avatar-image .mt-avatar-boosted {
  width: 2.25rem;
  height: 2.25rem;
  border-radius: 0.25rem;
  overflow: hidden;
}
.mt-avatar-image .mt-avatar-account {
  width: 1.5rem;
  height: 1.5rem;
  top: 1.5rem;
  left: 1.5rem;
  position: absolute;
  border-radius: 0.25rem;
  overflow: hidden;
}
.mt-user {
  /* display: table; */
  width: auto !important;
  font-weight: 600;
  margin-bottom: 1rem;
}
.mt-user > a {
  color: var(--content-text) !important;
}

/* Text */
.toot-text {
  margin-bottom: 0.25rem;
  color: var(--content-text);
}
.toot-text .spoiler-link {
  display: inline-block;
}
.toot-text .spoiler-text-hidden {
  display: none;
}
.toot-text.truncate {
  display: -webkit-box;
  overflow: hidden;
  -webkit-line-clamp: var(--text-max-lines);
  -webkit-box-orient: vertical;
}
.toot-text:not(.truncate) .ellipsis::after {
  content: "...";
}
.toot-text blockquote {
  border-left: 0.25rem solid var(--line-gray-color);
  margin-left: 0;
  padding-left: 0.5rem;
}
.toot-text .custom-emoji {
  height: 1.5rem;
  min-width: 1.5rem;
  margin-bottom: -0.25rem;
  width: auto;
}


/* Poll */
.toot-poll {
  margin-bottom: 0.25rem;
  color: var(--content-text);
}
.toot-poll ul {
  list-style: none;
  padding: 0;
  margin: 0;
}
.toot-poll ul li {
  font-size: 0.9rem;
  margin-bottom: 0.5rem;
}
.toot-poll ul li:not(:last-child) {
  margin-bottom: 0.25rem;
}
.toot-poll ul li:before {
  content: "◯";
  padding-right: 0.5rem;
}

/* Medias */
.toot-media {
  overflow: hidden;
  margin-bottom: 0.5rem;
}
.toot-media-preview {
  position: relative;
  margin-top: 0.25rem;
  height: auto;
  text-align: center;
  width: 100%;
}
.toot-media > .spoiler-link {
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 1;
  transform: translate(-50%, -50%);
}
.toot-media-spoiler > img {
  filter: blur(2rem);
}
.toot-media-preview a {
  display: block;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.img-ratio14_7 {
  position: relative;
  padding-top: 56.95%;
  width: 100%;
}
.img-ratio14_7 > img {
  width: 100%;
  height: auto;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
}

/* Preview link */
.toot-preview-link {
  min-height: 4rem;
  display: flex;
  flex-direction: row;
  border: 1px solid var(--line-gray-color);
  border-radius: 0.5rem;
  color: var(--link-color);
  font-size: 0.8rem;
  margin: 1rem 0 0.5rem 0;
  overflow: hidden;
}
.toot-preview-image {
  width: 40%;
  align-self: stretch;
}
.toot-preview-image img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.toot-preview-noImage {
  width: 40%;
  font-size: 1.5rem;
  align-self: center;
  text-align: center;
}
.toot-preview-content {
  width: 60%;
  display: flex;
  align-self: center;
  flex-direction: column;
  padding: 0.5rem 1rem;
  gap: 0.5rem;
}
.toot-preview-title {
  font-weight: 600;
}

/* Spoiler button */
.spoiler-link {
  border-radius: 2px;
  background-color: var(--line-gray-color);
  border: 0;
  color: var(--content-text);
  font-weight: 700;
  font-size: 0.7rem;
  padding: 0 0.35rem;
  text-transform: uppercase;
  line-height: 1.25rem;
  cursor: pointer;
  vertical-align: top;
}

/* Date */
.toot-date {
  font-size: 0.75rem;
}

/* Error */
.mt-error {
  position: absolute;
  display: flex;
  flex-direction: column;
  height: calc(100% - 3.5rem);
  width: calc(100% - 4.5rem);
  justify-content: center;
  align-items: center;
  color: var(--error-text-color);
  padding: 0.75rem;
  text-align: center;
}
.mt-error-icon {
  font-size: 2rem;
}
.mt-error-message {
  padding: 1rem 0;
}
.mt-error-message hr {
  color: var(--line-gray-color);
}

/* Loading spinner */
.mt-body > .loading-spinner {
  position: absolute;
  width: 3rem;
  height: 3rem;
  margin: auto;
  top: calc(50% - 1.5rem);
  right: calc(50% - 1.5rem);
}
.loading-spinner {
  background-image: url("data:image/svg+xml,%3Csvg height='160' width='160' viewBox='0 0 180 180' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3Cstyle type='text/css' %3E%3C!%5BCDATA%5B .logo_class %7B transform-origin: 50%25 50%25 0px; animation-name: animlogo; animation-duration: 1s; animation-timing-function: linear; animation-iteration-count: infinite; %7D @keyframes animlogo %7B 50%25 %7B transform: rotate3d(0, 1, 0, -180deg); %7D 100%25 %7B transform: rotate3d(0, 0, 1, 360deg); %7D %7D %5D%5D%3E%3C/style%3E%3C!-- %23%23 you can remove this rect - it's just here to orient the developere %23%23 --%3E%3C!-- %3Crect style='stroke:rgb(200,200,200); fill:rgb(255,255,255);' x='0' y='0' width='180' height='180'%3E%3C/rect%3E --%3E%3C!-- %23%23 you can scale this (e.g., divide all numbers by 3) but you will have to scale the viewbox by the same factor %23%23 --%3E%3Cg fill='none' stroke='%23000' stroke-width='7.5' stroke-linecap='round' class='logo_class'%3E%3Cpolyline points='63 65 139 87 164 58' id='top_line' %3E%3C/polyline%3E%3Cpolyline points='17 122 41 92 117 115' id='bottom_line' %3E%3C/polyline%3E%3Cpolyline points='128 101 52 79' id='middle_line' %3E%3C/polyline%3E%3C/g%3E%3C/svg%3E%0A");
  /* background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns:svg='http://www.w3.org/2000/svg' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' version='1.0' viewBox='0 0 128 128' %3E%3Cg%3E%3Cpath d='M64 128A64 64 0 0 1 18.34 19.16L21.16 22a60 60 0 1 0 52.8-17.17l.62-3.95A64 64 0 0 1 64 128z' fill='%23404040'/%3E%3CanimateTransform attributeName='transform' type='rotate' from='0 64 64' to='360 64 64' dur='1000ms' repeatCount='indefinite'%3E%3C/animateTransform%3E%3C/g%3E%3C/svg%3E"); */
  background-repeat: no-repeat;
  background-position: center center;
  background-color: transparent;
  background-size: min(2.5rem, calc(100% - 0.5rem));
}

/* Footer (See more link) */
.mt-footer {
  margin: 1rem auto 2rem auto;
  padding: 0 2rem;
  text-align: center;
}

/* Hidden elements */
.visually-hidden {
  position: absolute !important;
  width: 1px !important;
  height: 1px !important;
  padding: 0 !important;
  margin: -1px !important;
  overflow: hidden !important;
  clip: rect(0, 0, 0, 0) !important;
  white-space: nowrap !important;
  border: 0 !important;
}
